@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

/* body {font-family: 'Roboto', sans-serif;} */
.main-sidebar {
  background-color: #f6f9fb;
  border-right: 1px solid lightgray;
}

.link-container {
  padding-left: 1em;
  padding-right: 1em;
}

.link-container a, .link-container a:hover {
  color: #1a1f36;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

.link-container-selected {
  color: #5469d4 !important;
  font-weight: bold;
}

.main-panel {
  padding-top: 20px;
}

.btn {
  font-size: 14px;
}

.table-hover-tr:hover {
  cursor: pointer;
  background: rgb(238, 239, 247);
}

.table {
  font-size: 14px;
}

.copy-clipboard-client-name, .copy-clipboard-client-name:hover {
  cursor: pointer;
  color:#5469d4;
  font-weight: bold;
}